.title {
  color: $black;
  font-size: 48px;
  line-height: 1.2;
  font-family: $font-family-title;
  font-weight: bold;
  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }
}
