.main-menu {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }

  > ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > li {
      list-style: none;
      font-size: 1rem;
      white-space: nowrap; /* Prevents text from wrapping */
      
      > a {
        padding: 10px 12px;
        display: inline-block;
        font-weight: normal;
        text-decoration: none;
        color: $header-text-color;
        white-space: nowrap; /* Ensures the anchor text does not wrap */
        
        &:hover {
          text-decoration: underline;
        }
      }

      &.active {
        > a {
          font-weight: bold;
          text-decoration: none;

          &:hover {
            text-decoration: none;
            transition: all 225ms ease-in 0s;
          }
        }
      }
    }
  }
}