.logo {
  display: none;
  background-position: center;

  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: baseline;
  }
  img {
    max-width: unset;
  }
  a {
    display: block;
    // width: 100%;
    height: 100%;
  }
  p {
    padding-left: 20px;

  }
}
.logo-mobile {
  display: block;
  width: 40px;
  padding: 10px 0 10px 0;
  @include media-breakpoint-up(sm) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}