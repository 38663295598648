.feature {
  height: 100%;
  border: 1px solid $white-offset;
  border-radius: 9px;
  padding: 20px;
  background-color: $white;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: $black;
  // shadow
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  .feature-image {
    flex: 0 0 auto;
    // width: 80px;
    // height: 80px;
    display: flex;
    margin-bottom: 20px;
    text-align: center;
  }
  img {
    // width: 100%;
    height: auto;
  }
  .feature-title {
    font-family: $font-family-title;
    color: $secondary;
  }
  .feature-content {
    margin-bottom: 0;
    margin-top: auto;
  }
}


a > .feature + a {
  /* This applies to the parent a tag */
  /* Example parent styles */
  display: block;
  // on hower add shadow
  &:hover {
    .feature {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    }
  }
}