.header {
  color: $header-text-color;
  background-color: $header-background-color;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-title{
    font-family: $font-family-heading;
    font-weight: bold;
  }
  // box-shadow: 0 1px 15px rgba(50, 50, 93, 0.2);
  // border-bottom: 1px solid $white-offset;
  padding: 10px 30px 15px 0px;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &.header-absolute {
    position: absolute;
    z-index: 10;
    width: 100%;
  }
  margin-bottom:-25px;
  z-index:0;

  a {
    color: $header-text-color;
  }
  p {
    color: $header-text-color;
    font-size: 24px;
  }  
}
.lock-scroll {
  .header {
    &.header-absolute {
      position: static;
    }
  }
}

.flower-chain{
  border-bottom: 60px solid transparent; /* Set the width of the border */
  border-image: url($baseurl + '/images/illustrations/daisies-small.png') 35 1 repeat; /* Adjust based on the image */
  z-index: 1;
}

