$baseurl: "";

// Colors
$primary: #FFCC33;
$primary-light: lighten($primary, 20%);

$primary-dark: #9f7b0e;
$secondary: #FC9601;
$secondary-light: lighten($secondary, 10%);
$black: #2f2f41;
$white: #ffffff;
$white-offset: #f0f0f0;
$steel: #5c5a5a;

// Links
$link-color: $secondary;
$link-decoration: none;
$link-hover-color: lighten($primary, 10%);
$link-hover-decoration: underline;

// Fonts
$font-family-base: Helvetica, Arial, sans-serif, -apple-system;
$font-family-heading: "Playfair Display", serif, -apple-system;
$font-family-title: 'Merienda', cursive;


// Header
$header-background-color: $primary;
$header-text-color: $black;

// Footer
$footer-background-color: $primary;
$footer-text-color: $black;
$sub-footer-background-color: darken($primary, 10%);
$sub-footer-text-color: $black;

// Bootstrap 5.3.2

// 1. Include Bootstrap functions first
@import "bootstrap/functions";

// 2. Include our variable overrides here
@import "bootstrap-variables";

// 3. Include main bootstrap variables and functions
@import "bootstrap/variables";
@import "bootstrap/variables-dark";
@import "bootstrap/maps";
@import "bootstrap/mixins";
@import "bootstrap/utilities";

// 4. Include optional Bootstrap components
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/containers";
@import "bootstrap/grid";
@import "bootstrap/tables";
@import "bootstrap/forms";
// @import "bootstrap/buttons";
// @import "bootstrap/transitions";
// @import "bootstrap/dropdown";
// @import "bootstrap/button-group";
// @import "bootstrap/nav";
// @import "bootstrap/navbar";
// @import "bootstrap/card";
// @import "bootstrap/accordion";
// @import "bootstrap/breadcrumb";
// @import "bootstrap/pagination";
// @import "bootstrap/badge";
// @import "bootstrap/alert";
// @import "bootstrap/progress";
// @import "bootstrap/list-group";
// @import "bootstrap/close";
// @import "bootstrap/toasts";
// @import "bootstrap/modal";
// @import "bootstrap/tooltip";
// @import "bootstrap/popover";
// @import "bootstrap/carousel";
// @import "bootstrap/spinners";
// @import "bootstrap/offcanvas";
// @import "bootstrap/placeholders";

// 5. Include Bootstrap helpers and utilities
@import "bootstrap/helpers";
@import "bootstrap/utilities/api";

// Libraries
@import "libraries/hamburgers/hamburgers";

// Components
@import "components/type";
@import "components/page";
@import "components/header";
@import "components/footer";
@import "components/sub-footer";
@import "components/logo";
@import "components/main-menu";
@import "components/main-menu-mobile";
@import "components/hamburger";
@import "components/buttons";
@import "components/call";
@import "components/title";
@import "components/content";
@import "components/intro";
@import "components/intro-image";
@import "components/strip";
@import "components/feature";
@import "components/social";
// @import "components/fonts"; // Uncomment this line to self host font

// Pages
@import "pages/page-home";
@import "pages/page-teams";
@import "pages/page-service";

body {
  font-size: 16px;
  line-height: 1.2;
  font-family: $font-family-base;
  @include media-breakpoint-up(md) {
    font-size: 16px;
    line-height: 1.3;
  }
}
